import * as _ from "lodash"
import dayjs from "dayjs"
import * as React from "react"
import ExportsJS from "exportsjs"
import {
    Alert,
    Button,
    FormControl,
    InputGroup,
    Card
} from "../wrappers"
import { DateRange } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
    FormattedNumber,
    FormattedRelativeTime,
    FormattedTime
} from "react-intl"
import { PageState } from "../PageState"
import { firestore, currentDatabaseRef } from "../../config/constants"
import { Role } from "../../config/role"
import { StripedTable } from "../StripedTable"
// import "react-dates/initialize" // tslint:disable-line
import * as utc from "dayjs/plugin/utc"
import * as dayjstimezone from "dayjs/plugin/timezone"
import { RoleRouterProps, withRoleRouter } from "../../routes"
import { Pagination } from "react-bootstrap"
import { orderKindName } from "./SaleView"
import { getFunctions, httpsCallable } from "firebase/functions"
import { collection, DocumentData, endAt, getDocs, limitToLast, onSnapshot, orderBy, Query, query, QueryDocumentSnapshot, startAt, where } from "firebase/firestore"
import { child, get, orderByKey, limitToLast as dbLimitToLast, query as dbQuery, startAt as dbStartAt, endAt as dbEndAt, off, onValue, orderByChild, limitToFirst, equalTo } from "firebase/database"
import { FormattedCurrency } from "../FormattedCurrency"

dayjs.extend(utc as any)
dayjs.extend(dayjstimezone as any)
const FileDownload = require("js-file-download")

type SearchType = "giftcard" | "customer" | "sale"

interface SalesListState {
    sales: any[]
    lastCheckoutTimestamp?: string
    firstCheckoutTimestamp?: string
    newestCheckoutTimestamp?: string
    startDate: Date
    endDate: Date
    exports: any
    loaded: boolean
    focusedInput?: any
    lastPage?: boolean
    errorMessage?: string
    giftcardModuleEnabled: boolean
    voucherModuleEnabled: boolean
    searchTerm?: string
    searching: boolean
    searchType?: SearchType
    giftcardRecipients?: _.Dictionary<string>
    datePickerCollapsed: boolean,
    includeB2BCustomerInCSV: boolean
    b2bModuleEnabled: boolean
}

function isNumeric(input: string) {
    return /^-?\d+$/.test(input)
}

function allSalesTotal(checkout: any): number {
    if (checkout.voided === true) {
        return 0
    }
    const sales = checkout.sales ?? []
    const total = sales.reduce((sum: number, value: any) => { return sum + value.basket.total }, 0)
    return total
}

function lineItemCounts(checkout: any): React.ReactElement {
    const sales = checkout.sales ?? []
    const counts: number[] = []
    for (const sale of sales) {
        if (sale.basket?.line_items) {
            const count = sale.basket.line_items.reduce((sum: number, value: any) => { return sum + value.quantity }, 0)
            counts.push(count)
        } else {
            counts.push(0)
        }
    }
    return <>{counts.map<React.ReactNode>((number, index) => <FormattedNumber key={index} useGrouping={true} minimumFractionDigits={0} maximumFractionDigits={0} value={number} />)
        .reduce((prev, curr) => [prev, ", ", curr])}</>
}

export function summaryType(checkout: any): string {
    const sales = checkout.sales ?? []
    const types: string[] = []
    for (const sale of sales) {
        types.push(saleSummaryType(sale.basket ?? {}))
    }
    return types.join(", ")
}

export function saleSummaryType(summary: any): string {
    if (!_.isNil(summary.return_reference)) {
        return "Return"
    } else if (!_.isNil(summary.external_order_reference)) {
        if (summary.line_items.length > 0 && !_.isNil(summary.line_items[0].order_kind)) {
            return orderKindName(summary.line_items[0].order_kind)
        }
        return "Order"
    } else if (!_.isNil(summary.sales_quote_reference)) {
        return "Sale of sales quote"
    } else if (!_.isNil(summary.expense_reference)) {
        return "Expense"
    }
    return "Sale"
}

class SalesList extends React.Component<RoleRouterProps, SalesListState> {
    constructor(props: RoleRouterProps) {
        super(props)

        this.state = {
            sales: [],
            lastCheckoutTimestamp: undefined,
            exports: {},
            loaded: false,
            giftcardModuleEnabled: false,
            voucherModuleEnabled: false,
            searching: false,
            datePickerCollapsed: false,
            startDate: new Date(),
            endDate: new Date(),
            includeB2BCustomerInCSV: false,
            b2bModuleEnabled: false
        }
    }

    async componentDidMount() {
        this.setupObservers()
        const params = new URLSearchParams(this.props.router.location.search)
        const key = params.get("search")
        const giftcardCode = params.get("giftcard_code")

        if (giftcardCode !== null) {
            this.setState({ searchTerm: giftcardCode })
            await this.loadGiftcardSales(giftcardCode, this.props)
        } else if (key !== null) {
            this.setState({ searchTerm: key })
            await this.loadSearchItems(key)
        } else {
            await this.loadSales(this.earlierThan)
        }
        await this.loadIncludeB2BCustomerInCSV()
        await this.loadExports()
    }

    async loadIncludeB2BCustomerInCSV() {
        const account = this.props.role.account_id
        const b2bRef = child(currentDatabaseRef(), `v1/accounts/${account}/configuration/modules/b2b`)
        const snapshot = await get(b2bRef)
        const b2b = snapshot.val()
        this.setState({ includeB2BCustomerInCSV: b2b?.include_b2b_customer_in_csv === true, b2bModuleEnabled: b2b?.enabled === true })
    }

    async UNSAFE_componentWillReceiveProps(nextProps: RoleRouterProps) {
        const prevShop = this.props.role.shop_id ?? this.props.router.params.shopKey
        const nextShop = nextProps.role.shop_id ?? this.props.router.params.shopKey
        if (prevShop !== nextShop) {
            await this.loadSales(this.earlierThan, nextProps)
        }
    }

    loadExports = async () => {
        const account = this.props.role.account_id
        const exportsRef = child(currentDatabaseRef(), `v1/accounts/${account}/configuration/export_integrations/sales`)
        const exportsQuery = dbQuery(exportsRef, orderByKey(), dbLimitToLast(50))
        const snapshot = await get(exportsQuery)
        let exportsDict = snapshot.val() || {}
        exportsDict = _.filter(exportsDict, (value, key: string, collection) => {
            if (Object.prototype.hasOwnProperty.call(value, "show_in_portal")) {
                return value.show_in_portal
            }
            return true
        })
        exportsDict["line_items"] = {
            name: "All line items as CSV file",
            transformation: {
                type: "csv",
                parameters: {
                    configuration: { csv_separator: ";", decimal_separator: "," },
                    columns: [
                        { header: "sale_id", value: "sale_id" },
                        { header: "type", value: "type" },
                        { header: "sequence_number", value: "sequence_number" },
                        { header: "timestamp", value: "timestamp" },
                        { header: "timezone", value: "timezone" },
                        { header: "cashier_id", value: "cashier_id" },
                        { header: "cashier_name", value: "cashier_name" },
                        { header: "register_id", value: "register_id" },
                        { header: "register_name", value: "register_name" },
                        { header: "shop_id", value: "shop_id" },
                        { header: "shop_name", value: "shop_name" },
                        { header: "market_id", value: "market_id" },
                        { header: "market_name", value: "market_name" },
                        { header: "product_id", value: "id" },
                        { header: "product_group", value: "product_group" },
                        { header: "name", value: "name" },
                        { header: "variant_id", value: "variant_id" },
                        { header: "variant_name", value: "variant_name" },
                        { header: "quantity", value: "quantity" },
                        { header: "barcode", value: "barcode" },
                        { header: "total", value: "total" },
                        { header: "total_tax_amount", value: "total_tax_amount" },
                        { header: "vat_amount", value: "vat_amount" },
                        { header: "sales_tax_amount", value: "sales_tax_amount" },
                        { header: "sub_total", value: "sub_total" },
                        { header: "retail_price", value: "retail_price" },
                        { header: "base_price", value: "base_price" },
                        { header: "cost_price", value: "cost_price" },
                        { header: "margin", value: "margin" },
                        { header: "margin_total", value: "margin_total" },
                        { header: "discount_amount", value: "discount_amount" },
                        { header: "image_url", value: "image_url" },
                        { header: "customer_id", value: "customer_id" },
                        { header: "externally_paid", value: "externally_paid" }
                    ],
                    rows: [
                        {
                            type: { id: "line_items_each" }
                        }
                    ]
                }
            }
        }

        if (this.state.includeB2BCustomerInCSV) {
            this.addB2BCustomerInfo(exportsDict)
        }

        this.setState({ exports: exportsDict })
    }

    private addB2BCustomerInfo(exportsDict: any) {
        (exportsDict["line_items"].transformation.parameters.columns as any[]).push(
            { header: "organization_number", value: "organization_number" },
            { header: "customer_name", value: "customer_name" },
            { header: "street", value: "street" },
            { header: "city", value: "city" },
            { header: "postal_code", value: "postal_code" },
            { header: "phone", value: "phone" },
            { header: "email", value: "email" }
        )
    }

    // Helpers

    private set earlierThan(key: string | undefined) {
        const params = new URLSearchParams(this.props.router.location.search)
        if (params.get("earlierThan") === key) {
            return
        }
        if (key) {
            params.set("earlierThan", key)
        } else {
            params.delete("earlierThan")
        }

        this.props.router.navigate(`?${params.toString()}`)
    }

    private get earlierThan(): string | undefined {
        const params = new URLSearchParams(this.props.router.location.search)
        const key = params.get("earlierThan")

        return key === null ? undefined : key
    }

    private renderGiftcardDetails(checkout: any) {
        const giftcards: any[] = []
        for (const sale of checkout.sales ?? []) {
            for (const lineItem of sale.basket?.line_items ?? []) {
                if (lineItem.behavior?.giftcard?.code) {
                    const amount = lineItem.total
                    giftcards.push([lineItem.behavior?.giftcard?.code, true, "giftcard", amount])
                } else if (lineItem.behavior?.giftcard_use?.code) {
                    const amount = -lineItem.total
                    giftcards.push([lineItem.behavior?.giftcard_use?.code, false, "giftcard", amount])
                } else if (lineItem.behavior?.voucher?.code) {
                    const amount = lineItem.total
                    giftcards.push([lineItem.behavior?.voucher?.code, true, "voucher", amount])
                } else if (lineItem.behavior?.voucher_use?.code) {
                    const amount = -lineItem.total
                    giftcards.push([lineItem.behavior?.voucher_use?.code, false, "voucher", amount])
                }
            }
        }
        for (const payment of checkout.payments ?? []) {
            if (!payment.success) { continue }
            const amount = payment.amount
            if (payment.payment_type === "giftcard.integration") {
                if (payment.metadata?.status === "used") {
                    giftcards.push([payment.metadata?.code, false, "giftcard", amount])
                } else {
                    giftcards.push([payment.metadata?.code, true, "giftcard", -amount])
                }
            } else if (payment.payment_type === "voucher.integration") {
                if (payment.metadata?.status === "used") {
                    giftcards.push([payment.metadata?.code, false, "voucher", amount])
                } else {
                    giftcards.push([payment.metadata?.code, true, "voucher", -amount])
                }
            }
        }
        return giftcards.map(a => {
            const [code, isIssued, type, amount] = a
            const email = this.state.giftcardRecipients?.[code]
            return <p key={code}>{isIssued ? "Issued" : "Used"} {type} of <b>{amount} {checkout.base_currency_code}</b> with code: <i>{code}</i>{email && <div>Sent to: <b>{email}</b></div>}</p>
        })
    }

    private get showSearchDetails(): boolean {
        return this.state.searching
    }

    unregisterSales?: () => void
    unregisterGiftcardModule?: (a: any) => void
    unregisterVoucherModule?: (a: any) => void

    componentWillUnmount(): void {
        if (this.unregisterSales) {
            this.unregisterSales()
            this.unregisterSales = undefined
        }
        if (this.unregisterGiftcardModule) {
            off(child(currentDatabaseRef(), `v1/accounts/${this.props.role.account_id}/configuration/modules/giftcard/enabled`), "value", this.unregisterGiftcardModule)
            this.unregisterGiftcardModule = undefined
        }
        if (this.unregisterVoucherModule) {
            off(child(currentDatabaseRef(), `v1/accounts/${this.props.role.account_id}/configuration/modules/voucher/enabled`), "value", this.unregisterVoucherModule)
            this.unregisterGiftcardModule = undefined
        }
    }

    private setupObservers() {
        if (this.unregisterSales) { this.unregisterSales() }
        this.unregisterSales = onSnapshot(query(this.checkoutsRef(), orderBy("timing.timestamp_date_string"), limitToLast(1)), snapshot => {
            if (!snapshot || snapshot.empty) {
                return
            }

            if (snapshot.docs.length > 0) {
                const timestamp = snapshot.docs[0].data()?.timing?.timestamp_date_string
                this.setState({ newestCheckoutTimestamp: timestamp })
            } else {
                this.setState({ newestCheckoutTimestamp: undefined })
            }
        })
        this.unregisterGiftcardModule = onValue(child(currentDatabaseRef(), `v1/accounts/${this.props.role.account_id}/configuration/modules/giftcard/enabled`), snapshot => {
            this.setState({ giftcardModuleEnabled: snapshot.val() ?? false })
        })
        this.unregisterVoucherModule = onValue(child(currentDatabaseRef(), `v1/accounts/${this.props.role.account_id}/configuration/modules/voucher/enabled`), snapshot => {
            this.setState({ voucherModuleEnabled: snapshot.val() ?? false })
        })
    }

    private _loadNext = async (props = this.props) => {
        const limit = 50
        const fromKey = this.state.lastCheckoutTimestamp
        let salesRef = query(this.checkoutsRef(props), orderBy("timing.timestamp_date_string"), limitToLast(limit))
        if (!_.isNil(fromKey)) {
            salesRef = query(salesRef, endAt(fromKey))
        }

        await this.loadSalesFromRef(salesRef, true)
    }
    public get loadNext() {
        return this._loadNext
    }
    public set loadNext(value) {
        this._loadNext = value
    }

    loadPrevious = async (props = this.props) => {
        const docLimit = 50
        const fromKey = this.earlierThan
        let salesRef = query(this.checkoutsRef(props), orderBy("timing.timestamp_date_string"), limitToLast(docLimit))
        if (!_.isNil(fromKey)) {
            salesRef = query(salesRef, startAt(fromKey))
        }

        await this.loadSalesFromRef(salesRef, true)
    }

    shop(props = this.props): string | undefined {
        return props.role.shop_id ?? props.router.params.shopKey
    }

    checkoutsRef(props = this.props) {
        const account = props.role.account_id
        const shop = this.shop(props)
        const checkoutsRef = collection(firestore, `accounts/${account}/checkouts`)

        if (!_.isNil(shop)) {
            return query(checkoutsRef, where("source.shop_id", "==", shop))
        } else {
            return checkoutsRef
        }
    }

    fbSalesRef(props = this.props) {
        const account = props.role.account_id
        const shop = this.shop(props)
        if (shop) {
            return child(currentDatabaseRef(), `v1/accounts/${account}/shops/${shop}/sales`)
        } else {
            return child(currentDatabaseRef(), `v1/accounts/${account}/sales`)
        }
    }

    loadSalesFromRef = async (salesRef: Query<DocumentData>, updateQuery: boolean = false) => {
        const limit = 50
        const snapshot = await getDocs(salesRef)

        if (snapshot.empty) {
            this.setState({ loaded: true, lastPage: true })
            return
        }

        const values: any[] = []
        for (const checkoutDoc of snapshot.docs) {
            const checkout = checkoutDoc.data()
            checkout.key = checkoutDoc.id
            values.push(checkout)
        }

        let lastSaleId: string | undefined = undefined
        let firstSaleId: string | undefined = undefined
        const reverseOrder = values.reverse()
        if (reverseOrder.length > 0) {
            firstSaleId = reverseOrder[0].timing.timestamp_date_string
            if (updateQuery) {
                this.earlierThan = firstSaleId
            }
            lastSaleId = reverseOrder[reverseOrder.length - 1].timing.timestamp_date_string
        }
        this.setState({ loaded: true, sales: reverseOrder, firstCheckoutTimestamp: firstSaleId, lastCheckoutTimestamp: lastSaleId, lastPage: values.length < limit })
    }

    loadSales = async (fromKey: string | undefined, props: RoleRouterProps = this.props) => {
        const limit = 50
        let salesRef = query(this.checkoutsRef(props), orderBy("timing.timestamp_date_string"), limitToLast(limit))
        if (!_.isNil(fromKey)) {
            salesRef = query(salesRef, endAt(fromKey))
        }
        this.loadSalesFromRef(salesRef)
    }

    async fetchGiftcard(code: string) {
        const args: any = {
            action: "giftcard-read",
            account: this.props.role.account_id,
            code: code,
        }

        const client = httpsCallable(getFunctions(), "clientApi")
        try {
            return await client(args)
        } catch {
            return
        }
    }

    getSalesById = async (searchTerm: string, props: RoleRouterProps = this.props): Promise<any> => {
        // Sale identifiers in Ka-ching are upper cased.
        const upperCased = searchTerm.toUpperCase()
        const rtdbSalesQuery = dbQuery(this.fbSalesRef(props), orderByChild("identifier"), dbStartAt(upperCased), dbEndAt(`${upperCased}~`), limitToFirst(10))
        const rtdbSales = await get(rtdbSalesQuery)

        if (rtdbSales.exists()) {
            return rtdbSales.val() ?? {}
        } else if (isNumeric(searchTerm)) {
            const rtdbSalesQuery = dbQuery(this.fbSalesRef(props), orderByChild("sequence_number"), equalTo(parseInt(searchTerm)), limitToFirst(10))
            const rtdbSales = await get(rtdbSalesQuery)
            return rtdbSales.val() ?? {}
        }
        return {}
    }

    getCheckoutsBySales = async (sales: any, props: RoleRouterProps = this.props): Promise<QueryDocumentSnapshot<DocumentData>[]> => {
        const saleIdentifiers: string[] = Object.values(sales).map((sale: any) => { return sale.identifier })
        if (saleIdentifiers.length === 0) {
            return []
        }

        const queries = saleIdentifiers.map(identifier => { return getDocs(query(this.checkoutsRef(props), where("index.sale_identifiers", "array-contains", identifier))) })
        const snaps = await Promise.all(queries)
        let docs: QueryDocumentSnapshot<DocumentData>[] = []
        for (const snap of snaps) {
            docs = docs.concat(snap.docs)
        }
        return docs
    }

    loadSearchItems = async (searchTerm: string, props: RoleRouterProps = this.props) => {
        this.setState({ sales: [], loaded: false, searching: true })
        const values: any[] = []
        let searchType: SearchType = "customer"
        const salesRef = query(this.checkoutsRef(props), where("index.customer_ids", "array-contains", searchTerm))
        const snapshot = await getDocs(salesRef)
        let docs: QueryDocumentSnapshot<DocumentData>[] = snapshot.docs
        if (docs.length === 0) {
            searchType = "sale"
            const sales: any = await this.getSalesById(searchTerm, props)
            docs = await this.getCheckoutsBySales(sales, props)
        }

        if (docs.length === 0) {
            searchType = "giftcard"
            await this.loadGiftcardSales(searchTerm, props)
            return
        }

        const alreadyAdded = new Set<string>()
        for (const document of docs) {
            const checkout = document.data()
            if (!alreadyAdded.has(document.id)) {
                checkout.key = document.id
                values.push(checkout)
                alreadyAdded.add(document.id)
            }
        }

        const sorted = Object.values(values).sort((a: any, b: any) => {
            return a.timing.timestamp - b.timing.timestamp
        })
        this.setState({ sales: sorted, searchType: searchType, lastPage: true, loaded: true, firstCheckoutTimestamp: undefined, giftcardRecipients: undefined })
    }

    loadGiftcardSales = async (code: string, props: RoleRouterProps = this.props) => {
        this.setState({ sales: [], loaded: false, searching: true })
        const values: _.Dictionary<any> = {}
        const toProcess: Set<string> = new Set()
        toProcess.add(code)
        const seen: Set<string> = new Set()
        while (toProcess.size > 0) {
            const first = toProcess.values().next().value
            toProcess.delete(first)
            if (seen.has(first)) {
                continue
            }
            seen.add(first)
            const salesRef = query(this.checkoutsRef(props), where("index.giftcard_codes", "array-contains", first))
            const snapshot = await getDocs(salesRef)

            if (snapshot.empty) {
                continue
            }

            for (const checkoutDoc of snapshot.docs) {
                const checkout = checkoutDoc.data()
                checkout.key = checkoutDoc.id
                for (const code of checkout.index?.giftcard_codes ?? []) {
                    if (seen.has(code)) {
                        continue
                    }
                    toProcess.add(code)
                }
                values[checkoutDoc.id] = checkout
            }
        }

        const sorted = Object.values(values).sort((a: any, b: any) => {
            return a.timing.timestamp - b.timing.timestamp
        })
        this.setState({ sales: sorted, searchType: "giftcard", lastPage: true, loaded: true, firstCheckoutTimestamp: undefined, giftcardRecipients: undefined })

        const giftcardCodeEmails: any = {}
        for (const code of seen) {
            const giftcard: any = await this.fetchGiftcard(code)
            if (giftcard?.data?.email) {
                giftcardCodeEmails[code] = giftcard.data.email
            }
            this.setState({ giftcardRecipients: giftcardCodeEmails })
            console.log(giftcard)
        }
    }

    performExport = async (exportKey: string) => {
        const exportMethod = this.state.exports[exportKey]

        const start = dayjs(this.state.startDate).startOf("day")
        const end = dayjs(this.state.endDate).endOf("day")
        if (!start.isValid()) {
            return
        }
        if (!end.isValid()) {
            return
        }

        const salesRef = dbQuery(this.fbSalesRef(), orderByChild("timing/timestamp_string"), dbStartAt(start.utc(false).format("YYYY-MM-DDTHH:mm:ss")), dbEndAt(end.utc(false).format("YYYY-MM-DDTHH:mm:ss")))
        const snapshot = await get(salesRef)
        if (!snapshot.exists()) {
            // TODO: Warn that no data is available?
            this.setState({ errorMessage: "No sales are present in the selected time interval." })
            return
        }
        const salesDict = snapshot.val()

        const sales = Object.values(salesDict)
        const configuration = exportMethod.transformation.parameters

        let exportFunction: (input: any) => any
        let fileExtension: string = "json"
        let singleItemExport: boolean = false

        switch (exportMethod.transformation.type) {
            case "csv": {
                exportFunction = input => {
                    const csvExport = new ExportsJS.csv(configuration, input)
                    return csvExport.export()
                }
                fileExtension = "csv"
                break
            }
            case "simplejson": {
                exportFunction = input => {
                    const jsonExport = new ExportsJS.simpleJSON(configuration, input)
                    return jsonExport.export()
                }
                singleItemExport = true
                break
            }
            case "identity": {
                exportFunction = input => { return input }
                break
            }
            case "economic": {
                exportFunction = input => {
                    const jsonExport = new ExportsJS.economic(configuration, input)
                    return jsonExport.saleExport()
                }
                singleItemExport = true
                break
            }
            default: {
                return
            }
        }

        const filename = "sales." + fileExtension
        if (singleItemExport) {
            const output: any = []
            for (const index in sales) {
                const sale = sales[index]
                try {
                    // Expecting export functions to return objects
                    output.push(exportFunction(sale))
                } catch (error) {
                    if (error instanceof ExportsJS.SkipExport) {
                        console.log("Skipping export", error.message)
                    } else {
                        throw error
                    }
                }
            }
            FileDownload(JSON.stringify(output), filename)

        } else {
            let output = exportFunction(salesDict)
            if (_.isObject(output)) {
                output = JSON.stringify(output)
            }
            FileDownload(output, filename)
        }
    }

    showSale = (saleKey: string) => {
        let path = `/sales/${saleKey}`
        const shop = this.shop()
        if (!_.isNil(shop)) {
            path = `/shop/${shop}/sales/${saleKey}`
        }
        this.props.router.navigate(path)
    }

    handleAlertDismiss = () => {
        this.setState({ errorMessage: undefined })
    }

    private get usesGiftcards() {
        return this.state.giftcardModuleEnabled || this.state.voucherModuleEnabled
    }

    private handleSearchTermChange = (event: any) => {
        const value: string = event.target.value

        this.setState({ searchTerm: value })
    }

    private handleSearchKeyPress(value: any) {
        if (value.charCode === 13) {
            this.performSearch()
        }
    }

    performSearch() {
        this.searchTerm = this.state.searchTerm
        this.loadSearchItems(this.state.searchTerm ?? "")
    }

    closeSearch() {
        this.setState({ searchTerm: undefined, searchType: undefined, loaded: false, sales: [], searching: false })
        this.searchTerm = undefined
        this.loadSales(undefined)
    }

    private set searchTerm(searchTerm: string | undefined) {

        // We always overwrite all query params, so we don't start out from the current params
        const params = new URLSearchParams()

        if (searchTerm) {
            const encodedSearchTerm = encodeURIComponent(searchTerm)
            params.set("search", encodedSearchTerm)
        } else {
            params.delete("search")
        }
        console.log(params.toString())

        this.props.router.navigate(`?${params.toString()}`)
    }

    private get searchTerm(): string | undefined {
        const params = new URLSearchParams(this.props.router.location.search)
        const encodedSearchTerm = params.get("search")

        if (!encodedSearchTerm) {
            return undefined
        }

        const searchTerm = decodeURIComponent(encodedSearchTerm)

        return searchTerm
    }

    renderSearchField() {
        // Show search field
        return <InputGroup>
            <FormControl
                type="text"
                name="search_term"
                value={this.state.searchTerm ?? ""}
                placeholder={`Search sales by sale id, sequence number${this.usesGiftcards ? ", gift card code" : ""} or customer id`}
                onChange={(event: any) => { this.handleSearchTermChange(event) }}
                onKeyPress={(value) => { this.handleSearchKeyPress(value) }}
            />

            <Button variant="primary" onClick={() => { this.performSearch() }} >Search</Button>
            {this.showSearchDetails &&
                <Button variant="danger" onClick={() => { this.closeSearch() }}>Close</Button>
            }

        </InputGroup>
    }

    private get onFirstPage() {
        return this.state.firstCheckoutTimestamp === this.state.newestCheckoutTimestamp
    }

    renderPager() {
        return <Pagination>
            <Pagination.Prev disabled={this.showSearchDetails || this.onFirstPage} onClick={async () => await this.loadPrevious()}>&larr; Newer</Pagination.Prev>
            <Pagination.Next disabled={this.showSearchDetails || this.state.lastPage} onClick={async () => { this.loadNext() }}>Older &rarr;</Pagination.Next>
        </Pagination>
    }

    renderCustomer(checkout: any): string {
        if (checkout.sales.length === 0) {
            return ""
        }
        const sale = checkout.sales[0]
        if (!_.isNil(sale.basket?.customer?.identifier)) {
            if (!_.isNil(sale.basket?.customer?.address?.name)) {
                return `${sale.basket.customer.address.name} (${sale.basket.customer.identifier})`
            } else {
                return sale.basket.customer.identifier
            }
        }
        return ""
    }

    render() {
        return (
            <PageState loading={false} typeName="sales">
                {this.renderSearchField()}
                <br></br>
                {this.renderPager()}
                <Card className="my-4">
                    <Card.Header>Sales</Card.Header>
                    <Card.Body>
                        {this.state.loaded ?
                            <StripedTable>
                                <thead>
                                    <tr>
                                        <th>Date/Time</th>
                                        {
                                            (this.showSearchDetails && this.state.searchType === "giftcard") &&
                                            <th>Gift card details</th>
                                        }
                                        { }                                       {
                                            ((this.showSearchDetails && this.state.searchType === "customer") || this.state.b2bModuleEnabled) &&
                                            <th>Customer</th>
                                        }
                                        <th>Type</th>
                                        <th>Source</th>
                                        <th style={{ textAlign: "right" }}>Line Items</th>
                                        <th style={{ textAlign: "right" }}>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.sales.map(listValue => {
                                        return (
                                            <tr style={{ color: listValue.voided ? "#cccccc" : "black" }} key={listValue.key} onClick={() => this.showSale(listValue.key)} >
                                                <td style={{ color: listValue.voided ? "#cccccc" : "black" }}>
                                                    <FormattedTime
                                                        value={new Date(listValue.timing.timestamp_string)}
                                                        day="numeric"
                                                        month="long"
                                                        year="numeric"
                                                        hour="numeric"
                                                        minute="numeric"
                                                    />
                                                    {/* &nbsp;(<FormattedRelativeTime unit="day" value={(new Date(listValue.timing.timestamp_string).getTime() - new Date().getTime()) / 1000000} />) */}
                                                </td>
                                                {
                                                    (this.showSearchDetails && this.state.searchType === "giftcard") &&
                                                    <td style={{ color: listValue.voided ? "#cccccc" : "black" }}>{this.renderGiftcardDetails(listValue)}</td>
                                                }
                                                {
                                                    ((this.showSearchDetails && this.state.searchType === "customer") || this.state.b2bModuleEnabled) &&
                                                    <td style={{ color: listValue.voided ? "#cccccc" : "black" }}>{this.renderCustomer(listValue)}</td>
                                                }
                                                <td style={{ color: listValue.voided ? "#cccccc" : "black" }}>{summaryType(listValue)}{
                                                    listValue.voided
                                                        ?
                                                        " (voided)"
                                                        :
                                                        ""
                                                }</td>
                                                <td style={{ color: listValue.voided ? "#cccccc" : "black" }}>{(_.isNil(this.shop()) || !_.isNil(this.searchTerm)) ? `${listValue.source.shop_name} / ` : ""} {listValue.source.cashier_name ?? listValue.source.cashier_display_name}</td>
                                                <td style={{ textAlign: "right", color: listValue.voided ? "#cccccc" : "black" }}>{lineItemCounts(listValue)}</td>
                                                <td style={{ textAlign: "right", color: listValue.voided ? "#cccccc" : "black" }}>
                                                    <FormattedCurrency value={allSalesTotal(listValue)} currency={listValue.base_currency_code} />
                                                </td>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </StripedTable>
                            : <div>Loading...</div>}
                    </Card.Body>
                </Card>
                {this.renderPager()}

                {Object.keys(this.state.exports).length > 0 ? (
                    <Card className="my-4">
                        <Card.Header>Export</Card.Header>
                        <Card.Body>
                            <div>
                                <DateRange onRangeFocusChange={() => this.setState({ datePickerCollapsed: false })} className={this.state.datePickerCollapsed ? "collapsed" : ""} editableDateInputs={true}
                                    onChange={item => {
                                        const range = item["selection"]
                                        if (!range) { return }
                                        if (!range.startDate || !range.endDate) {
                                            return
                                        }
                                        this.setState({ startDate: range.startDate, endDate: range.endDate })
                                    }}
                                    moveRangeOnFirstSelection={false}
                                    ranges={[{ startDate: this.state.startDate, endDate: this.state.endDate, key: "selection" }]} />

                                <br />
                                <br />
                                <br />
                                <br />
                            </div>
                            {
                                this.state.errorMessage && <Alert variant="danger" onClose={this.handleAlertDismiss}>
                                    <h4>Nothing to export</h4>
                                    <p>
                                        {this.state.errorMessage}
                                    </p>
                                    <p>
                                        <Button variant="secondary" onClick={this.handleAlertDismiss}>Ok</Button>
                                    </p>
                                </Alert>
                            }
                            <div>
                                {
                                    Object.keys(this.state.exports).map(key => {
                                        const exportMethod = this.state.exports[key]
                                        const start = dayjs(this.state.startDate).startOf("day")
                                        const end = dayjs(this.state.endDate).endOf("day")

                                        const enabled = start.isValid() && end.isValid()
                                        return <div key={key}><Button onClick={() => this.performExport(key)} disabled={!enabled}>{exportMethod.name}</Button><br /><br /></div>
                                    })
                                }
                            </div>
                        </Card.Body>
                    </Card>
                ) : <div />
                }
            </PageState>
        )
    }
}

export default withRoleRouter(SalesList)
